import React from 'react'
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import WPGBlocks from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import PageLayout from '@rentivo/gatsby-theme-barcelona/src/components/layouts/PageLayout';
import SeoHead from '@rentivo/gatsby-theme-barcelona/src/components/seo/SeoHead';
import PageHeader from '@rentivo/gatsby-theme-barcelona/src/components/page/PageHeader';
import Organization from '@rentivo/gatsby-core/src/components/schema/Organization';
import Brand from '@rentivo/gatsby-core/src/components/schema/Brand';
import ConditionalWrapper from '@rentivo/gatsby-core/src/components/generic/ConditionalWrapper';
import { Container } from '@rentivo/gatsby-core';
import { usePageData } from '@rentivo/gatsby-theme-barcelona/src/hooks/usePageData';
import { PageContextProvider } from '@rentivo/gatsby-core/src/context/PageContext';

const Page = (props) => {
  const { data, wpPage, pageContext } = props;
  const { wpPage: { title, isFrontPage, featuredImage, seo, slug } } = data;
  const { blocks, isPageHeader, isContained, menusAndBlocks } = usePageData(props);

  return (
    <PageContextProvider value={{...pageContext, ...wpPage}}>
      <PageLayout {...menusAndBlocks}>
        <Helmet>
          <body className={`page ${slug ? `page-${slug}` : ``}`} />
        </Helmet>
        <SeoHead seo={seo} backup={{seoTitle: title}} image={(featuredImage && featuredImage.node) ? featuredImage.node.sourceUrl : null}/>
        
        {isFrontPage && (
          <>
            <Organization/>
            <Brand/>
          </>
        )}
        {isPageHeader && title && <PageHeader title={title} featuredImage={featuredImage}/>}
        <ConditionalWrapper condition={isContained} wrapper={c => <Container content={true} mb={12}>{c}</Container>}>
          <WPGBlocks blocks={blocks} />
        </ConditionalWrapper>
      </PageLayout>
    </PageContextProvider>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;

export const pageQuery = graphql`
  query PAGE_BY_ID_QUERY($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      isFrontPage
      featuredImage {
        node {
          altText
          sourceUrl
        }
      }
      pageOptions {
        showPageHeader
      }
      seo {
        seoTitle
        seoMetaDescription
      }
      blocksJSON
    }
  }
`;
