import React from 'react';
import JsonLd from '@rentivo/gatsby-core/src/components/schema/JsonLd';

const OrganizationComponent = ({data, type}) => {
  if(!data) return null;

  const schemaData = {
    ...data
  };

  return (
    <JsonLd data={schemaData} type={type}/>
  );
};

export default OrganizationComponent;
