import React from 'react';

const JsonLdComponent = ({ data, type, context }) => {

  data = (Array.isArray(data) && Array.isArray(type) && type.length && data.length) ? data.map((d, i) => ({
    '@context': context,
    '@type': type[i],
    ...d
  })) : {
    '@context': context,
    '@type': type,
    ...data
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  );
};

export default JsonLdComponent;
