import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Brand from '@rentivo/gatsby-core/src/components/schema/Brand/component';
import { createSelector } from 'reselect';
import { selectSiteStructuredDataBrandConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

const mapStateToProps = createSelector(
  selectSiteStructuredDataBrandConfig,
  (data) => ({
    data
  })
);

const enhance = compose(
  connect(mapStateToProps),
);

Brand.defaultProps = {
  type: 'Brand'
};

export default enhance(Brand);
