import React, { useState, useCallback } from 'react';
import WPGBlocks from '@rentivo/gatsby-core/src/components/wpg/Blocks';
import { useDispatch } from 'react-redux';
import { setThemeOption } from '@rentivo/gatsby-core/src/theme/actions';

const FooterComponent = ({blocks}) => {
  const dispatch = useDispatch();
  const [ref, setRef] = useState(null);
  if(!blocks) return null;
  const onRefChange = useCallback(node => {
    setRef(node);
    if (node !== null) {
      dispatch(setThemeOption('footerHeight', node.clientHeight));
    }
  }, []);
  return <footer ref={onRefChange} id="footer"><WPGBlocks blocks={blocks} /></footer>;
};

export default FooterComponent;
