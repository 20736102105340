import React from 'react';
import { Container } from '@chakra-ui/react';

const ContainerComponent = ({children, fluid, content, ...rest}) => {
  return (
    <Container maxW={`container.${fluid ? 'full' : content ? 'lg' : 'xl'}`} {...rest}>
      {children}
    </Container>
  );
};

export default ContainerComponent;
