import { imageBgStyleObj } from '@rentivo/gatsby-core/src/containers/App/styles';
import { chakra } from '@chakra-ui/react';

export const StyledCover = chakra('div', {
  baseStyle: {
    position: "relative",
    backgroundColor: "gray.200",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    minHeight: { base: "350px", lg: "450px "},
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    ...imageBgStyleObj,
    "_after": {
      content: '""',
      zIndex: 1,
      pointerEvents: "none",
      position: "absolute",
      inset: "0px",
      backgroundColor: "rgba(0,0,0,0.2)"
    }
  }
});

export const StyledCoverInner = chakra('div', {
  baseStyle: {
    py: 8,
    px: { base: 0, lg: 4 },
    position: "relative",
    zIndex: 2,
    textAlign: "center",
    width: "100%",
    "h1, h2, p": { textShadow: "1px 1px 10px rgba(0,0,0,0.4)" },
    "h1, h2": { color: "white !important" },
    p: { color: "rgba(255, 255, 255, 0.8)" },
    ".meta": {
      color: "white",
      a: { color: "white", borderBottom: "2px solid white" },
      marginBottom: 4
    }
  }
});


export const StyledPageHeader = chakra('div', {
  baseStyle: {

  }
});

export const StyledPageHeaderTitleOnly = chakra('div', {
  baseStyle: {
    pt: 12,
    pb: 4
  }
});
