import React from 'react';
import {
  StyledCover, StyledCoverInner,
  StyledPageHeader,
  StyledPageHeaderTitleOnly
} from '@rentivo/gatsby-theme-barcelona/src/components/page/PageHeader/styles';
import CdnImage from '@rentivo/gatsby-core/src/components/ui/CdnImage';
import cdnProps from '@rentivo/gatsby-theme-barcelona/src/components/page/PageHeader/cdn';
import { Heading, Text } from '@chakra-ui/react';
import { Container } from '@rentivo/gatsby-core';

const PageHeader = ({title, subtitle, featuredImage, ...rest}) => {
  return (
    <StyledPageHeader {...rest}>
      {(featuredImage && featuredImage.node) ? (
        <StyledCover>
          {featuredImage.node.sourceUrl && <CdnImage uri={featuredImage.node.sourceUrl} alt={featuredImage.node.altText} {...cdnProps} />}
          <StyledCoverInner>
            <Container content={true} position="relative" zIndex={1}>
              <Heading variant="writing" as="h1" color={featuredImage.node.sourceUrl ? 'white' : 'text'}>{title}</Heading>
              {subtitle && <Text color={featuredImage.node.sourceUrl ? 'whiteAlpha.700' : 'textLight'} variant="writing">{subtitle}</Text>}
            </Container>
          </StyledCoverInner>
        </StyledCover>
      ) : (
        <StyledPageHeaderTitleOnly>
          <Container content={true} position="relative" zIndex={1}>
            <Heading variant="writing" as="h1">{title}</Heading>
            {subtitle && <Text colorScheme="gray" variant="writing">{title}</Text>}
          </Container>
        </StyledPageHeaderTitleOnly>
      )}
    </StyledPageHeader>
  );
};

export default PageHeader;
