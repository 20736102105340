import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Organization from '@rentivo/gatsby-core/src/components/schema/Organization/component';
import { createSelector } from 'reselect';
import { selectSiteStructuredDataOrganizationConfig } from '@rentivo/gatsby-core/src/selectors/siteConfig';

const mapStateToProps = createSelector(
  selectSiteStructuredDataOrganizationConfig,
  (data) => ({
    data
  })
);

const enhance = compose(
  connect(mapStateToProps),
);

Organization.defaultProps = {
  type: 'Organization'
};

export default enhance(Organization);
