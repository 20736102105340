import React from "react";
import { window } from "browser-monads";
import { Helmet } from "react-helmet";
import OpenGraph from "@rentivo/gatsby-core/src/components/schema/OpenGraph";

const SeoHead = ({ seo, backup, image }) => {
  const {
    seoTitle: seoTitleBackup,
    seoMetaDescription: seoMetaDescriptionBackup,
  } = backup;
  const { seoTitle, seoMetaDescription } = seo;
  const title = seoTitle ? seoTitle : seoTitleBackup;
  const description = seoMetaDescription
    ? seoMetaDescription
    : seoMetaDescriptionBackup;

  const urlPieces = [
    window.location.protocol,
    "//",
    window.location.host,
    window.location.pathname,
  ];
  const url = urlPieces.join("");

  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {window.location && <link rel="canonical" href={url} />}
      </Helmet>
      <OpenGraph title={title} description={description} imageSrc={image} />
    </>
  );
};

SeoHead.defaultProps = {
  seo: {},
  backup: {},
};

export default SeoHead;
